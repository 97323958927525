import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { Subscription } from 'rxjs';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { param } from '../../../http-params';
import { TableComponent } from '../../table.component';

@Component({
	selector: 'pcg-delete-button',
	templateUrl: './delete-button.component.html',
	styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent<T> implements OnDestroy {
	
	@Input() url: string; // URL to send delete request to
	@Input() id: string; // The id of the record to delete
	@Input() noConfirm = false; // By-pass the confirmation message
	@Input() useDefaultDelete = true; // Whether or not to use the default delete
	@Input() confirmMessage = 'Are you sure you want to delete this?'; // Confirmation message
	@Input() table: TableComponent<T>; // Table for ajax reload
	@Input() useTrashIcon: boolean; // Boolean to determine whether to use 'X' svg or trash icon

	@Output() delete = new EventEmitter<any>(); // Can be used to trigger a custom function on delete
	
	subscriptions = new Subscription(); // Used to keep track of subscriptions	
	faIconName = { faTrashCan };

	constructor(private ms: SystemMessageService) { }

	deleteRecord() {
		if (this.useDefaultDelete) {
			if (
				this.noConfirm 
				|| confirm(this.confirmMessage)
			) {
				const idQueryStr = typeof this.id === 'string' || typeof this.id === 'number' 
					? ('id=' + encodeURIComponent(this.id)) 
					: param(this.id);
				this.subscriptions.add(
					this.ms.getHttpObservable(this, this.url + '?' + idQueryStr).subscribe(obj => {
						this.delete.emit(obj);
						if (this.table) { this.table.ajaxReload(); }
				}));
			}
		} else if (this.noConfirm || confirm(this.confirmMessage)) { this.delete.emit(); }
	}

	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
