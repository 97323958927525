import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { faFingerprint, faUsbDrive } from '@fortawesome/pro-solid-svg-icons';

import { LoginVm } from 'app/shared/generated/Models/LoginVm';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessageService, SystemMessage } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { WebAuthnService } from 'app/core/security/multi-factor-auth/web-authn.service';
import { MultiFactorAuthModalComponent } from './multi-factor-auth-modal/multi-factor-auth-modal.component';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { FidoService } from 'app/shared/services/fido.service';
import { FingerprintService } from 'app/core/security/multi-factor-auth/fingerprint.service';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'pcg-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy, OnInit, AfterViewInit {

	@ViewChild('myForm', { static: true }) myForm: ElementRef;
	@ViewChild('footerEl', { static: true }) footerEl: ElementRef;

	loginForm = LoginVm.Form;
	subscriptions: Subscription = new Subscription();

	showMfa = false;
	faFingerprint = faFingerprint;
	faUsbDrive = faUsbDrive;
	userId: number;
	showInactiveMessage = false;
	
	constructor(
		private route: ActivatedRoute
		, private ms: SystemMessageService
		, private sec: SecurityService
		, private router: Router
		, private modalService: NgbModal
		, private webAuthn: WebAuthnService
		, private fingerprint: FingerprintService
		, private httpClient: HttpClient
		, title: Title
	) { title.setTitle('Login'); }

	ngOnInit() {
		const user = this.sec.getUser();
		if (user) { this.loginRedirect(); }

		document.cookie = "hasSpentFiveMinutesInactive=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		this.checkIfRedirectedFromInactivity();
	}

    ngAfterViewInit() {
		const footContainer = document.querySelector('#footer .container');
		footContainer.insertBefore(this.footerEl.nativeElement, footContainer.firstChild);
    }

	onSubmit() {
		if (this.loginForm.valid) {
			this.sec.setLocalStorage('lastAccess', new Date());
			this.httpClient.post("api/Account/Login", this.loginForm.getRawValue())
				.subscribe((msg: SystemMessage) => { 
					if (msg != null && msg.message != null && msg.messageClass != null) { this.ms.setSystemMessage(msg.message, msg.messageClass); }
					this.doLogin(msg);
				});
		} else { validateForm(this.loginForm); }
	}

	doLogin(msg: SystemMessage) {
		const val = msg.value;
		if (!val) { return; }
		
		if (val.userId) { this.userId = val.userId; }
		if (val.fidoMfa && val.fingerprintMfa) { this.showMfa = true; }
		else if (val.fidoMfa) {
			// Handle fido MFA
			this.myForm.nativeElement.ownerDocument.activeElement.blur(); // Need this hack to avoid change detection error on modal open
			this.modalService.open(MultiFactorAuthModalComponent, { animation: false }); // Open the 2FA modal
			this.doTwoFactorAuth(); // Handle 2FA
		} else if (val.fingerprintMfa) {
			// Handle fingerprint MFA
			this.doFingerprintIdentification();
		} else {
			// Successful login
			this.loginForm.reset();
			this.sec.setSecurity(val.token, val.user);
			this.loginRedirect();
		}
	}

	loginRedirect() {
		let redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl') || sessionStorage.getItem('redirectUrl');
		if(LoginComponent.detectMultiRedirect(redirectUrl)) {
			this.router.navigate(['/dashboard']);
			return;
		}
		if (redirectUrl) {
			const baseUrl = document.getElementsByTagName('base')[0].href;
			redirectUrl = redirectUrl.replace(/^\//, '');
			if (redirectUrl.startsWith('uploads/') || redirectUrl.startsWith('quartz')) {
				window.location.href = baseUrl + redirectUrl;
			} else {
				this.router.navigateByUrl(redirectUrl);
				sessionStorage.removeItem('redirectUrl');
			}
		} else { this.router.navigate(['/dashboard']); }
	}

	// If there is more than one redirect in the url, it could cause the page to render the login component 
	// with top navigation instead of redirecting to the desired location
	static detectMultiRedirect(redirectUrl: string): boolean{
		const badUrlSubStr = 'redirectUrl';
		if(FormatHelperService.GetIsNully(redirectUrl)) { return false; }
		return redirectUrl.indexOf(badUrlSubStr) > 0;
	}

	doTwoFactorAuth() {
		if (!this.webAuthn.isFidoCompatible()) {
			this.modalService.dismissAll();
			return;
		}

		// Save user credentials and clear form
		const userName = this.loginForm.value.userName;
		const password = this.loginForm.value.password;
		this.loginForm.reset();

		// Get assertion options from the server for user
		this.webAuthn.getAssertionOptions(userName, password)
			.subscribe(async makeAssertionOptions => {
				try { 
					makeAssertionOptions = FidoService.fixAssertionOptions(makeAssertionOptions);
					let credential = await navigator.credentials.get({ publicKey: makeAssertionOptions });

					this.sec.setLocalStorage('lastAccess', new Date());
					this.webAuthn.verifyAssertion(credential, userName, password)
						.subscribe((msg: SystemMessage) => {
							this.modalService.dismissAll();
							this.doLogin(msg);
						});
				} catch (e) {
					this.modalService.dismissAll();
					this.webAuthn.verifyAssertion(null, userName, password)
						.subscribe((msg: SystemMessage) => { this.doLogin(msg); });
					console.log("Error : " + e);
				} 				
			}
		);
	}

	fidoMfa() {
		// Handle fido MFA
		this.myForm.nativeElement.ownerDocument.activeElement.blur(); // Need this hack to avoid change detection error on modal open
		this.modalService.open(MultiFactorAuthModalComponent, { animation: false }); // Open the 2FA modal
		this.doTwoFactorAuth(); // Handle 2FA
	}

	fingerprintMfa() { this.doFingerprintIdentification(); }

	async doFingerprintIdentification() {
		const response = await this.fingerprint.loginFingerprintMfa(this.userId);
		// Fire the save event
		if (response !== null) {
			if (response?.isSuccessful) { 
				this.sec.setLocalStorage('lastAccess', new Date());
				this.httpClient.post("api/Account/Login?fingerprintSuccess=true", this.loginForm.getRawValue())
					.subscribe((msg: SystemMessage) => { this.doLogin(msg); });
			} else { this.ms.setSystemMessage("Incorrect Fingerprint.", "error"); }
		} 
	}

	checkIfRedirectedFromInactivity() {
		if (this.route.snapshot.queryParams.inactive != null) {
			this.showInactiveMessage = true
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.footerEl.nativeElement.parentNode.removeChild(this.footerEl.nativeElement);
	}
}
