import { UserService } from 'app/shared/services/user/user.service';
import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const getInventoryNav: () => NavRoute = () => {
	const emptyRoute: NavRoute = { name: "" };
	const user = UserService.getUser();

	if (user == null) return emptyRoute;
	if (user.systemInventory == null || user.systemInventory.length === 0) return emptyRoute;

	const navItems = user.systemInventory.map<NavRoute>((sysInv) => (
		{
			name: sysInv.name.length > 25 ? sysInv.name.substring(0, 25) + "..." : sysInv.name,
			href: `inv/${sysInv.name}/${sysInv.sysInvInventoryId}/products`,
			children:
				[
					{
						name: 'Products', 
						path: `inv/${sysInv.name}/${sysInv.sysInvInventoryId}/products`,
					},
					{
						name: 'Replenishments',
						path: `inv/${sysInv.name}/${sysInv.sysInvInventoryId}/replenishments`,
					},
					{
						name: 'Reconciliations', 
						path: `inv/${sysInv.name}/${sysInv.sysInvInventoryId}/reconciliations`
					}
				]
		})
	);

	const viewAll = { name: 'View All Inventories', exact: true, path: 'inv/list' }

	const navRoute: NavRoute = {
		name: 'Inventory',
		href: 'inv/list',
		exact: true,
		children: [
			{
				name: 'Inventories',
				href: 'inv/list',
				children: [...navItems.splice(0, 5), viewAll],
				featureFlag: true
			},
			{
				name: "EPCIS",
				exact: true,
				path: "inv/epcis-list"
			}
		]
	}

	return navRoute;
};