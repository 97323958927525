import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { SystemMessageService } from '../system-message/system-message-service';
import { SpinnerService } from '../layout/spinner/spinner.service';
import { SecurityService } from '../security/security.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

	reqCount = 0;

	constructor(
		private ms: SystemMessageService
		, private spinner: SpinnerService
		, private sec: SecurityService
		, private http: HttpClient
		, private router: Router
		, private modal: NgbModal
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		++this.reqCount;
		this.setSpinner();

		// Get the URL, with automatic base URL, if beginning with 'api/' or '/api/'
		const origUrl: string = req.url;
		let url = req.url.indexOf('api/') === 0 || req.url.indexOf('/api/') === 0
			? document.getElementsByTagName('base')[0].href + req.url
			: req.url;
		url = url.replace('//api', '/api');

		// Add the UTC offset as a query string
		if (!origUrl.startsWith('http')) {
			const timeZone = typeof Intl.DateTimeFormat().resolvedOptions !== 'undefined'
				? Intl.DateTimeFormat().resolvedOptions().timeZone
				: undefined;
			url = url 
				+ (url.includes('?') ? '&' : '?') 
				+ `userTimeZone=${encodeURIComponent(timeZone)}&` 
				+ `utcOffset=${new Date().getTimezoneOffset() / -60}`;
			url = url + `&t=${new Date().toISOString()}`; // Don't cache things running through this interceptor
		}

		// Set the default content type and URL
		let newReq = req.headers['Content-Type'] || req.body instanceof FormData
			? req.clone({ url })
			: req.clone({
				headers: req.headers.set('Content-Type', 'application/json')
				, url
			});

		// Get the auth token from the service.
		const authToken = localStorage.getItem('jwt') ? JSON.parse(localStorage.getItem('jwt')) : null;

		// Add the auth token to the header, if we have one
		if (
			authToken 
			&& !origUrl.startsWith('http')
		) {
			newReq = newReq.clone({ headers: newReq.headers.set('Authorization', 'Bearer ' + authToken) });
		}

		// Set new last access date
		this.sec.setLocalStorage('lastAccess', new Date());

		// Send cloned request with header to the next handler.
		return next.handle(newReq).pipe(
			catchError(this.handleError.bind(this)),
			finalize(() => { 
				--this.reqCount; 
				this.setSpinner(); 
			})
		);
	}

	/**
	 * This is our default error handling function throughout the application.
	 * @param err The error response.
	 */
	handleError(err: HttpErrorResponse) {
		switch (err.status) {
			case 401:
				this.modal.dismissAll(); // Make sure all modals are closed since they are moving to the login screen
				this.sec.setSecurity(null, null); // Clear out the user security
				const redirectUrl = '/' + window.location.href.replace(document.getElementsByTagName('base')[0].href, '');
				this.router.navigateByUrl(`/login?redirectUrl=${encodeURIComponent(redirectUrl)}&inactive=true`);
				break;
			case 403:
				let user = this.sec.getUser();
				if (user) { 
					this.ms.setSystemMessage(
						"Access Denied! You don't have access to this module. Review your module access from your profile or open a new support ticket if you believe this is a mistake."
						, "error"
					); 
				}
				this.http.get('api/Account/GetUserInfo').subscribe((model: any) => {
					this.sec.setSecurity(this.sec.getToken(), model.user);
					this.router.navigateByUrl('/');
				});
				break;
			case 500:
				this.ms.setSystemMessage('Internal server error.', 'error');
				break;
			case 503:
				this.ms.setSystemMessage(
					'CloudABIS is currently down. Please wait a minute to see if the service it back up.',
					'error',
					10000
				);
				break;
			case 466: // This can sometimes be used to indicate confirmation messages, so ignore here
				break;
			case 467: // The user does not have 2FA or inactivity timeout enabled
				this.ms.setSystemMessage('2FA and Inactivity Timeout must be enabled to view PHI.', 'error');
				this.modal.dismissAll(); // Make sure all modals are closed since they are moving to the splash screen
				this.router.navigateByUrl('/');
				break;
			case 468: // Invalid request, redirect to dashboard without showing user the error message.
				this.router.navigate(['/dashboard']);
				break;
			case 418: // User is missing MFA or inactivity timeout required for accessing sensitive area. Redirect to edit user security tab. 
				this.modal.dismissAll();
				this.router.navigateByUrl(err.error); 
				break;
			default:
				if (err.url.startsWith('http://localhost:15896')) {
					this.ms.setSystemMessage(
						'Could not connect to CloudScanr. Please ensure that the CloudScanr application is running.',
						'error'
					);
				} else {
					this.ms.setSystemMessage(
						'The request could not be processed because a network transmission error occurred.',
						'error'
					);
				}
		}
		return throwError(err);
	}

	/**
	 * Show and hide the spinner, depending on whether or not a request
	 * is still out.
	 */
	setSpinner() {
		if (this.reqCount === 0) { this.spinner.hideSpinner(); } 
		else { this.spinner.showSpinner(); }
	}
}
