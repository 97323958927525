<div class='w-100 mb-1 table-top-container clearfix no-print'>

	<div class="top-left-container form-inline">
		<!-- Non-mobile button container -->
		<div class="btn-container hide-on-print d-flex align-items-start">
			<pcg-button-hide-show 
				*ngIf="canShowHideColumns"
				class="hidden-sm mr-2" 
				[columnDefs]="columnDefs" 
			></pcg-button-hide-show>

			<pcg-button-filter 
				*ngIf='filterId?.length' 
				id="pcg-button-filter" 
				class="mr-2 hidden-sm" 
				[filterId]="filterId"
			></pcg-button-filter>

			<pcg-button-reset-filter 
				*ngIf='showResetFilterBtn()' 
				class="mr-2 hidden-sm"
			></pcg-button-reset-filter>
		</div>
	
		<!-- Mobile button container -->
		<div class="btn-container hidden-md-plus d-flex align-items-start mb-2">
			<pcg-button-hide-show 
				*ngIf="canShowHideColumns"
				class="mr-2" 
				[columnDefs]="columnDefs" 				
			></pcg-button-hide-show>

			<pcg-button-filter 
				*ngIf='filterId' 
				id="pcg-button-filter" 
				[filterId]="filterId" 
				class="mr-2"
			></pcg-button-filter>

			<pcg-button-reset-filter 
				*ngIf='showResetFilterBtn()' 
				class="mr-2"
			></pcg-button-reset-filter>
		</div>
	</div>

	<!-- Applied filters -->
	<div 
		*ngIf="
			filterMap 
			&& btnFilterService.getActiveFilters(ajaxData, filterMap)
		"
	>
		<div 
			class="applied-filters top-left-container form-inline alert-success d-flex flex-row align-items-start form-control-sm" 
			role="alert"
		>Applied Filters: {{ btnFilterService.getActiveFilters(ajaxData, filterMap) }}</div>
	</div>

	<div 
		*ngIf="canGlobalSearch" 
		class='search-container form-inline no-print'
	>
		<div 
			*ngIf="canExactMatchSearch" 
			class='exact-match-container'
		>
			<div class='form-check form-check-inline exactMatch'>
				<label 
					[for]='"exactMatch" + uniqueId' 
					class='form-check-label mr-2'
				>
					Exact Match
				</label>
				<ui-switch 
					#exactMatch 
					[id]='"exactMatch" + uniqueId'
					(click)='exactMatchSource.next(exactMatch.checked)'
				></ui-switch>
			</div>
		</div>
		<div class='order-first order-md-last'>
			<div class='hidden-md-plus'>
				<label class='for'>Search:</label>
			</div>
			<input 
				type='search' 
				class='form-control form-control-sm' 
				placeholder="Search..." 
				(input)='filterSource.next($event.target.value)'
			/>
		</div>
	</div>

	<div class="inline-page-actions">
		<div id="pageActions">
			<button 
				*ngIf="
					canExportTableToExcel 
					|| excelUrl
				" 
				[ngbTooltip]="excelTooltip" 
				(click)="getExcel()" 
				class="new-page-action"
			>
				<img src="assets/images/actions/xlsxgreen.svg">
			</button>
			<button 
				*ngIf="
					canPrintTable 
					|| printUrl
				" 
				[ngbTooltip]="printTooltip" 
				(click)="print()" 
				class="new-page-action"
			>
				<img src="assets/images/actions/printBlue.svg">
			</button>
			<button 
				*ngIf="canExportTableToPdf || pdfUrl" 
				[ngbTooltip]="pdfTooltip" 
				(click)="getPdf()" 
				class="new-page-action"
			>
				<img src="assets/images/actions/PDF.svg">
		</button>
		</div>
	</div>
</div>