import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { QuicklinkModule } from 'ngx-quicklink';
import { ToastrModule } from 'ngx-toastr';

import { DatadogErrorHandler } from 'datadog_config';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

/**
 * This is the first module loaded. It bootstraps
 * the rest of the application.
 */
@NgModule({
	declarations: [ AppComponent ],
	imports: [
		BrowserModule.withServerTransition({ appId: 'ng-cli-universal' })
		, CoreModule
		, QuicklinkModule
		, HttpClientModule
		, ToastrModule.forRoot({ positionClass: 'toast-top-center' })
		, BrowserAnimationsModule
		, ServiceWorkerModule.register(
			'ngsw-worker.js'
			, {
				enabled: true
				, registrationStrategy: 'registerImmediately'
			}
		)
	],
	providers: [
		{ 
			provide: ErrorHandler
			, useClass: DatadogErrorHandler
		} 
	],
	bootstrap: [ AppComponent ]
})
export class AppModule { }