import { Component, OnInit } from '@angular/core';
import { HubConnectionBuilder, LogLevel, HubConnection } from '@microsoft/signalr';

import { PushNotificationService } from './push-notification.service';

@Component({
	selector: 'pcg-push-notification',
	templateUrl: './push-notification.component.html',
	styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {
	
	showNotification = false;
	scheduledInstall: boolean;
	navSpacerVisible: boolean;	

	private hubConnection: HubConnection | undefined;

	constructor() { }

	/** Reload the page */
	hardReload() {
		window.location.reload();
		return false;
	}

	ngOnInit() {
		var url = window.location.href;
		var isLocalHost: boolean = url.indexOf('localhost') > -1;

		// WebSockets
		if (!isLocalHost) {
			this.hubConnection = new HubConnectionBuilder()
				.configureLogging(LogLevel.Critical)
				.withUrl('/install-notify')
				.build();

			this.hubConnection.on('install', () => {
				this.showNotification = true;
				localStorage.removeItem('update-timer');
				PushNotificationService.checkForSystemUpdate();
				this.scheduledInstall = false;
				this.navSpacerVisible = PushNotificationService.checkForNavSpace();
			});

			this.hubConnection.on('install-scheduled', (scheduledTime: Date) => {
				localStorage.setItem('update-timer', JSON.stringify(scheduledTime));
				this.scheduledInstall = PushNotificationService.checkForSystemUpdate() != null;
			});

			this.hubConnection.start().catch(err => console.error(err.toString()));

			this.scheduledInstall = PushNotificationService.checkForSystemUpdate() != null;

			const setTimerEvent = () => {
				var hasUser = localStorage.getItem('user') != null;
				if (this.showNotification === false && hasUser == true) {
					// Time to wait before attempting reconnect. Randomized to prevent denial of servicing ourselves.
					const timeToWait = Math.trunc(Math.random() * 10000) % 6 + 1;
					// Continuously try to reconnect to server on failure
					setTimeout(() => { this.hubConnection.start().catch(setTimerEvent); }, timeToWait);
				}
			};

			this.hubConnection.onclose(setTimerEvent);
		}
		
	}
}
