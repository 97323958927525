import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class VerificationVm {
	verificationEnum?: any;
	verificationStr?: string;
	numberOfVerificationsRequired?: any;
	verificationTypesAllowed?: any;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'verificationEnum': new UntypedFormControl(null, [...(validators['verificationEnum'] ?? []), ...[]]),
			'verificationStr': new UntypedFormControl(null, [...(validators['verificationStr'] ?? []), ...[]]),
			'numberOfVerificationsRequired': new UntypedFormControl('', [...(validators['numberOfVerificationsRequired'] ?? []), ...[Validators.required]]),
			'verificationTypesAllowed': new UntypedFormControl(null, [...(validators['verificationTypesAllowed'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): VerificationVmFromFg {
		return new VerificationVmFromFg(fg);
	}
}

export class VerificationVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get verificationEnum(): any {
		return this.fg.get('verificationEnum').value;
	}
	set verificationEnum(val) {
		this.fg.get('verificationEnum').setValue(val);
	}

	get verificationStr(): string {
		return this.fg.get('verificationStr').value;
	}
	set verificationStr(val) {
		this.fg.get('verificationStr').setValue(val);
	}

	get numberOfVerificationsRequired(): any {
		return this.fg.get('numberOfVerificationsRequired').value;
	}
	set numberOfVerificationsRequired(val) {
		this.fg.get('numberOfVerificationsRequired').setValue(val);
	}

	get verificationTypesAllowed(): any {
		return this.fg.get('verificationTypesAllowed').value;
	}
	set verificationTypesAllowed(val) {
		this.fg.get('verificationTypesAllowed').setValue(val);
	}
}
