import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { Subscription } from 'rxjs';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { PfsRegisterVm } from 'app/shared/generated/Models/Public/PfsRegisterVm';
import { pcgSettings } from 'app/shared/generated/pcg-settings';

@Component({
    selector: 'app-pfs-register',
    templateUrl: './pfs-register.component.html',
    styleUrls: ['./pfs-register.component.scss']
})
export class PfsRegisterComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent;
    siteKey: string = environment.siteKey; // should pick up environment specific siteKey

    public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsReady = false;	
	public recaptcha: any = null;
    public execute: any;
    emptyCaptcha: boolean = true;
    honeypotControl;
    
    subscriptions: Subscription = new Subscription();
    formGroup = PfsRegisterVm.Form;
    model = PfsRegisterVm.GetModel(this.formGroup);
    
    emailMatch = true;    
    isSuccess = false;

    pfsUrl : string = "";

    constructor(
        private ms: SystemMessageService
        , private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() { 
        this.setEnv(); 
        this.setUpHoneyPot();
        this.formGroup.addControl('recaptcha', new UntypedFormControl('', Validators.required));
    }

    ngAfterViewInit(): void {
        this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

    setUpHoneyPot() {
		this.formGroup.controls['honeyPot'].setValidators(this.requireFalse());
		this.formGroup.updateValueAndValidity();
	}

    requireFalse() : ValidatorFn {
		return (control : AbstractControl) : ValidationErrors | null => {
			if (control.value === true) { return { honeyPot: true}; }
			return null;
		}
	}

    handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.cdr.detectChanges();
	}

    handleReady(): void {
		this.captchaIsReady = true;
		this.cdr.detectChanges();
        this.execute = setInterval(() => {
			this.captchaElem?.execute();
            this.captchaElem?.getResponse() !== '' 
                ? this.emptyCaptcha = false 
                : this.emptyCaptcha = true;
		}, 2000);
    }

    handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
    }

    handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
    }

    setEnv() {
        if (pcgSettings.mode === 'Development') { this.pfsUrl = "https://localhost:44384/"; } 
        else if (pcgSettings.mode === 'Demo') { this.pfsUrl = "https://demo.fdohcentralpharmacy.com/"; 	} 
        else { this.pfsUrl = "https://fdohcentralpharmacy.com/"; }
	}

    sanitize(email: string, isConfirm: boolean) {
        var strTempString = email.trim();
        strTempString = strTempString.replace("'", "");
        strTempString = strTempString.replace("--", "");
        strTempString = strTempString.replace("/", "");
        strTempString = strTempString.replace("&", "");
        strTempString = strTempString.replace("?", "");
        strTempString = strTempString.replace(":", "");
        strTempString = strTempString.replace(",", "");
        strTempString = strTempString.replace(";", "");
        strTempString = strTempString.replace("NULL", "");
        strTempString = strTempString.replace("xp_", "");
        strTempString.replace(/[\n\r]+/g, '');
        strTempString.replace('\\', '');

        if (isConfirm === true) { this.formGroup.controls['confirmEmail'].setValue(strTempString); } 
        else { this.formGroup.controls['email'].setValue(strTempString); }
    }

    onSubmit() {
        if (this.formGroup.controls['confirmEmail'].value !== this.formGroup.controls['email'].value) {
            this.emailMatch = false;
        }
        
        if (this.formGroup.valid) {            
            this.subscriptions.add(this.ms.getHttpObservable(this, 'api/PublicPfsRegister/RegisterPfsUser', this.formGroup)
                .subscribe(msg => {
                    if (msg.isSuccessful) {
                        this.isSuccess = true;
                        this.formGroup.reset();
                    } else { this.isSuccess = false; }
                 }));
        } else { 
            validateForm(this.formGroup); 
            this.isSuccess = false;
        }
    }

    ngOnDestroy() { 
        this.subscriptions.unsubscribe(); 
		clearInterval(this.execute);
    }
}
