import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SecurityService } from 'app/core/security/security.service';
import { CiWorkstationComponent } from 'app/shared/ci-workstation/ci-workstation.component';
import { CiWorkstationVm } from 'app/shared/generated/CIRepack/Models/Admin/WorkStation/CiWorkstationVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';

@Injectable({
    providedIn: 'root'
})
export class WorkstationService {
    // May clean up this user stuff tomorrow!
    // user: UserJsVm
    @Output() update = new EventEmitter();
    workStationName: string
    noWorkstation = false;

    constructor(private httpClient: HttpClient, private sec: SecurityService, private modalService: NgbModal) {
        let user = this.sec.getUser();
        this.getModel(user?.ciWorkstationId)
            .subscribe(data => {
                this.workStationName = data.workstationName;
                if (FormatHelperService.GetIsNullyOrWhitespace(data.workstationName)) {
                    this.workStationName = "None selected";
                    this.noWorkstation = true;
                }
            });
    }

    getModel(id: number): Observable<CiWorkstationVm> {
        let httpParams = new HttpParams().set('id', id);
        return this.httpClient.get<CiWorkstationVm>('api/CIRepack/CiWorkstation/GetCiWorkstation', {
            params: httpParams
        });
    }

    openCiWorkstationModal() {
        let user = this.sec.getUser();
        const modalRef = this.modalService.open(CiWorkstationComponent, { animation: false });
        modalRef.hidden.subscribe(() => {
            user = JSON.parse(localStorage.getItem('user'));
            this.workStationName = user?.ciWorkstationName;
            if (FormatHelperService.GetIsNullyOrWhitespace(user?.ciWorkstationName)) {
                this.workStationName = "None selected";
                this.noWorkstation = true;
            }
            else { this.noWorkstation = false; }
            this.update.emit();
        });
    }
}
