<div *ngIf="model$ | async">
    <form [formGroup]="formGroup">
        <div 
            *ngFor="let item of formGroup.get('verificationSettings').controls; let i = index" 
            formArrayName="verificationSettings"  
            class="mobile-center"
        >
            <div 
                [formGroupName]="i" 
                class="border panel-border panel-group"
            >
                <div class="panel-title border">{{ item.get("verificationStr").value }}</div>
                <div class="panel-body">
                    <label class="for">Number of Verifications</label>
					<br /><br />
                    <div class="btn-group d-flex">
                        <div 
							*ngFor="let itm of amountOfSigs; let s = index" 
							class="btn-group d-flex"
						>
							<button 
								type="button" 
								class="btn btn-outline-secondary" 
								[value]="itm.value" 
								[ngClass]="
                                    itm.value === item.get('numberOfVerificationsRequired').value 
                                        ? 'enable-nov' 
                                        : 'disable-btn'
                                "
								[ngStyle]="
                                    s === 2 
                                        ? { 'border-top-right-radius': '0.32em', 'border-bottom-right-radius': '0.32em' } 
                                        : null
                                "
								(click)="
                                    numVerificationsChange(
                                        itm.value
                                        , item.get('numberOfVerificationsRequired'), item.value
                                    );
                                "
							>{{ itm.text }}</button>
							<input 
								[id]="i + itm.value" 
								type="hidden" 
								pcgControlRef 
								formControlName="numberOfVerificationsRequired" 
								[getDataOnInit]="false" 
							/>
						</div>
                    </div>
                    <br /><br />

                    <label ngbTooltip="Icon color is green when selected and black if unselected">Verification Types Allowed</label>
					<br />

                    <div id="verification-types">
						<ng-container *ngFor="let itm of model.verificationChoices">
							<button 
								*ngIf="itm.value === 0" 
								type="button" 
								class="btn utility-button" 
								[value]="itm.value" 
								(click)="
									verificationTypeChange(
                                        itm.value
                                        , item.get('verificationTypesAllowed')
                                        , item.get('verificationTypesAllowed').value.indexOf(itm.value) !== -1
                                    );
								"
							>
								<fa-icon 
									[icon]="faIcon.faUsbDrive" 
									class="icon-size"
									[ngClass]="
                                        item.get('verificationTypesAllowed').value.indexOf(itm.value) !== -1 
                                            ? 'enable-vta' 
                                            : 'disable-btn'
                                    "
								></fa-icon>
								<div>Device</div>	
							</button>

							<button 
								*ngIf="itm.value === 1" 
								type="button" 
								class="btn utility-button" 
								[value]="itm.value" 
								(click)="
									verificationTypeChange(
                                        itm.value
                                        , item.get('verificationTypesAllowed')
                                        , item.get('verificationTypesAllowed').value.indexOf(itm.value) !== -1
                                    );
								"
							>
								<img 
									src="/assets/images/icons/fingerprint.svg" 
									width="48" 
									height="48"
									[ngClass]="
                                        item.get('verificationTypesAllowed').value.indexOf(itm.value) !== -1 
                                            ? 'enable-vta' 
                                            : 'disable-btn'
                                    "
								>
								<div>Fingerprint</div>
							</button>

							<button 
								*ngIf="itm.value === 2" 
								type="button" 
								class="btn utility-button" 
								[value]="itm.value" 
								(click)="
									verificationTypeChange(
                                        itm.value
                                        , item.get('verificationTypesAllowed')
                                        , item.get('verificationTypesAllowed').value.indexOf(itm.value) !== -1
                                    );
								"
							>
								<img 
									src="/assets/images/icons/pin.svg" 
									width="48" 
									height="48"
									[ngClass]="
                                        item.get('verificationTypesAllowed').value.indexOf(itm.value) !== -1 
                                            ? 'enable-vta' 
                                            : 'disable-btn'
                                    "
								>
								<div>PIN</div>
							</button>

							<button 
								*ngIf="itm.value === 3" 
								type="button" 
								class="btn utility-button" 
								[value]="itm.value" 
								(click)="
									verificationTypeChange(
                                        itm.value
                                        , item.get('verificationTypesAllowed')
                                        , item.get('verificationTypesAllowed').value.indexOf(itm.value) !== -1
                                    );
								"
							>
								<img 
									src="/assets/images/icons/keyboard.svg" 
									width="48" 
									height="48"
									[ngClass]="
                                        item.get('verificationTypesAllowed').value.indexOf(itm.value) !== -1 
                                            ? 'enable-vta' 
                                            : 'disable-btn'
                                    "
								>
								<div>Credentials</div>
							</button>

							<input 
								[id]="i + itm.value" 
								type="hidden" 
								pcgControlRef 
								formControlName="verificationTypesAllowed" 
								[getDataOnInit]="false" 
							/>
						</ng-container>
					</div>
                </div>
            </div>
        </div>
    </form>
</div>
