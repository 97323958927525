import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { addMinutes } from 'date-fns';

import { SecurityService } from '../security/security.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class InactivityTimeoutService {

	subsriptions = new Subscription();
	modalOpen = false;

	constructor(
		private sec: SecurityService
		, private router: Router
		, public modalService: NgbModal
	) { this.startTimer(); }

	startTimer() {
		const source = timer(0, 1000);
		this.subsriptions.add(source.subscribe(() => {
			const user = this.sec.getUser();
			if (user) {
				if (localStorage.getItem('lastAccess')) {
					const lastAccessedDate = new Date(JSON.parse(localStorage.getItem('lastAccess')));
					const endDate = addMinutes(lastAccessedDate, 5);
					if ((new Date()) >= endDate) {
						if (window.location.href.includes("fulfillment")
							|| window.location.href.includes("pharm-log")
							|| window.location.href.includes("metabolic-formula"))
						{
							this.modalService.dismissAll();
							this.sec.setSecurity(null, null);
							const redirectUrl = '/' + window.location.href.replace(document.getElementsByTagName('base')[0].href, '');
							this.router.navigateByUrl(`/login?redirectUrl=${encodeURIComponent(redirectUrl)}&inactive=true`);
						}
						var d = new Date();
						d.setTime(d.getTime() + (1*24*60*1000));
						document.cookie = `hasSpentFiveMinutesInactive=true;expires=${d.toUTCString()};path=/`; // this cookie allows the server to log you out if you were inactive at any point during the session
					}
				} else { this.sec.setSecurity(null, null); }
			}
		}));
	}

	stopTimer() { this.subsriptions.unsubscribe(); }

	getCookie(cname) {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for(let i = 0; i <ca.length; i++) {
		  let c = ca[i];
		  while (c.charAt(0) == ' ') {
			c = c.substring(1);
		  }
		  if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		  }
		}
		return "";
	  }
}
