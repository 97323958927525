<div class="modal-header bg-white rounded">
	<h5 class="modal-title" id="modal-basic-title">Assigned CI Workstation</h5>
	<button type="button" class="close" aria-label="Close" (click)="this.activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class='modal-body'>
    <div class="row">
        <div class="col-md-12">
            <label class="for">Select CI Workstation</label>
            <pcg-select #ciWorkstation id="ciWorkstation" [multiple]='false' placeholder='Select...' (ngModel)="this.ciWorkstation"
                (change)="workstationChange(ciWorkstation.value)"></pcg-select>
        </div>        
    </div>  
    <div class="row">
        <div *ngIf="this.user?.ciWorkstationId != null" class="center">
            <button class='btn btn-save btn-lg' (click)='this.activeModal.dismiss()'>Continue With Current Workstation</button>
        </div>  
    </div>    
</div>